<template>
  <div class="benefits rounded dashboard-usertype profile-info">
    <div class="border-bottom d-flex justify-content-between align-items-center pb-2">
      <div>
        <h4 class="section-title">
          <!-- {{ $t("accountSettings.label.security") }} -->
          Reset Password
        </h4>
      </div>
    </div>

    <!-- <b-row class="border-bottom mx-3">
      <b-col>
        <h4>
          <b>{{ $t("accountSettings.label.security") }}</b>
        </h4>
      </b-col> -->
    <!-- <b-col class="m-auto text-right">
        <b-button
          class="border-0 bg-transparent text-primary"
          :to="{ name: 'SecurityEdit' }"
        >
          <b-icon icon="pencil-square"></b-icon>
          {{ $t("global.button.edit") }}
        </b-button>
      </b-col> -->
    <!-- </b-row> -->

    <div class="">
      <div style="width: 400px" class="security-box">
        <b-row>
          <b-col cols="12" class="p-0 m-auto row">
            <b-col cols="12" class="pt-2">
              <label class="form-label">Old Password</label>
              <input
                type="password"
                class="form-control input-pills"
                v-model="oldPassword"
              />
            </b-col>

            <b-col cols="12" class="pt-0">
              <label class="form-label">New Password</label>
              <b-input-group>
                <template #append>
                  <b-input-group-text
                    class="bg-white border right-rounded"
                    :class="{
                      'border-danger': passwordValidate === false,
                      'border-success': passwordValidate,
                    }"
                    ><b-icon :icon="eyes" @click="showPassword()"></b-icon
                  ></b-input-group-text>
                </template>
                <b-form-input
                  :type="passwordField"
                  v-model="newPassword"
                  :state="passwordValidate"
                  @input="checkNewPassword()"
                  class="form-control left-rounded"
                ></b-form-input>
              </b-input-group>
            </b-col>

            <b-col>
              <b-card
                class="border mb-6"
                v-show="newPassword"
                :class="{
                  'border-danger': passwordValidate === false,
                  'border-success': passwordValidate,
                }"
              >
                <h6>Password must contain</h6>
                <p>at least</p>
                <b-row>
                  <b-col cols="8" class="text-danger">
                    <span :class="{ 'text-success': check1 }">6 Characters</span> </b-col
                  ><b-col cols="4" class="text-right"
                    ><b-icon v-if="check1" icon="check-circle" variant="success"></b-icon
                    ><b-icon v-else icon="x-circle" variant="danger"></b-icon
                  ></b-col>
                  <b-col cols="8" class="text-danger"
                    ><span :class="{ 'text-success': check2 }"
                      >One Upper Case</span
                    > </b-col
                  ><b-col cols="4" class="text-right"
                    ><b-icon v-if="check2" icon="check-circle" variant="success"></b-icon
                    ><b-icon v-else icon="x-circle" variant="danger"></b-icon
                  ></b-col>
                  <b-col cols="8" class="text-danger"
                    ><span :class="{ 'text-success': check3 }">One Number</span> </b-col
                  ><b-col cols="4" class="text-right"
                    ><b-icon v-if="check3" icon="check-circle" variant="success"></b-icon
                    ><b-icon v-else icon="x-circle" variant="danger"></b-icon
                  ></b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6" class="pr-2">
            <b-button pill variant="secondary" class="w-100" replace
              >{{ $t("global.button.cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6" class="pl-2">
            <b-button pill class="w-100" variant="primary" type="submit">
              <!-- {{ $t("accountSettings.button.updatePassword") }} -->
              Update Password
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      passwordRule1: /^.*(?=.{6,20}).*$/, //minimum 6 chars
      passwordRule2: /^.*(?=.*[A-Z]).*$/, //atleast 1 upper letter
      passwordRule3: /^.*(?=.*\d).*$/, //atleast 1 number
      check1: false,
      check2: false,
      check3: false,
      passwordField: "password",
      eyes: "eye",
      oldPasswordValidate: null,
      passwordValidate: null,
    };
  },
  methods: {
    checkNewPassword() {
      if (!this.newPassword) this.passwordValidate = null;
      else this.passwordValidate = false;
      this.check1 = false;
      this.check2 = false;
      this.check3 = false;
      if (this.passwordRule1.test(this.newPassword)) {
        this.check1 = true;
      }
      if (this.passwordRule2.test(this.newPassword)) {
        this.check2 = true;
      }
      if (this.passwordRule3.test(this.newPassword)) {
        this.check3 = true;
      }
      if (this.check1 && this.check2 && this.check3) this.passwordValidate = true;
    },
    showPassword() {
      if (this.passwordField === "password") {
        (this.passwordField = "text"), (this.eyes = "eye-slash");
      } else if (this.passwordField === "text") {
        (this.passwordField = "password"), (this.eyes = "eye");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.security-box {
  border: 1px solid #d0d0d0 !important;
  border-radius: 5px;
  width: 420px;
  margin: auto;
  padding: 24px;
  margin-top: 24px;
  input {
    height: 48px;
    margin-bottom: 24px;
    border-right: 0 !important;
  }
  .input-group-text {
    height: 48px;
    border: 1px solid #d0d0d0 !important;
    border-left: 0 !important;
  }
  .btn {
    height: 48px;
    font-size: 16px;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    &.btn-secondary {
      border: 1px solid $light-blue;
      color: $light-blue;
      background: none;
    }
    &:hover {
      background: #00559d !important;
      color: white !important;
    }
  }
}
</style>
