var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"benefits rounded dashboard-usertype profile-info"},[_vm._m(0),_c('div',{},[_c('div',{staticClass:"security-box",staticStyle:{"width":"400px"}},[_c('b-row',[_c('b-col',{staticClass:"p-0 m-auto row",attrs:{"cols":"12"}},[_c('b-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('label',{staticClass:"form-label"},[_vm._v("Old Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"form-control input-pills",attrs:{"type":"password"},domProps:{"value":(_vm.oldPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.oldPassword=$event.target.value}}})]),_c('b-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('label',{staticClass:"form-label"},[_vm._v("New Password")]),_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"bg-white border right-rounded",class:{
                    'border-danger': _vm.passwordValidate === false,
                    'border-success': _vm.passwordValidate,
                  }},[_c('b-icon',{attrs:{"icon":_vm.eyes},on:{"click":function($event){return _vm.showPassword()}}})],1)]},proxy:true}])},[_c('b-form-input',{staticClass:"form-control left-rounded",attrs:{"type":_vm.passwordField,"state":_vm.passwordValidate},on:{"input":function($event){return _vm.checkNewPassword()}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)],1),_c('b-col',[_c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"border mb-6",class:{
                'border-danger': _vm.passwordValidate === false,
                'border-success': _vm.passwordValidate,
              }},[_c('h6',[_vm._v("Password must contain")]),_c('p',[_vm._v("at least")]),_c('b-row',[_c('b-col',{staticClass:"text-danger",attrs:{"cols":"8"}},[_c('span',{class:{ 'text-success': _vm.check1 }},[_vm._v("6 Characters")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.check1)?_c('b-icon',{attrs:{"icon":"check-circle","variant":"success"}}):_c('b-icon',{attrs:{"icon":"x-circle","variant":"danger"}})],1),_c('b-col',{staticClass:"text-danger",attrs:{"cols":"8"}},[_c('span',{class:{ 'text-success': _vm.check2 }},[_vm._v("One Upper Case")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.check2)?_c('b-icon',{attrs:{"icon":"check-circle","variant":"success"}}):_c('b-icon',{attrs:{"icon":"x-circle","variant":"danger"}})],1),_c('b-col',{staticClass:"text-danger",attrs:{"cols":"8"}},[_c('span',{class:{ 'text-success': _vm.check3 }},[_vm._v("One Number")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.check3)?_c('b-icon',{attrs:{"icon":"check-circle","variant":"success"}}):_c('b-icon',{attrs:{"icon":"x-circle","variant":"danger"}})],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"pr-2",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"w-100",attrs:{"pill":"","variant":"secondary","replace":""}},[_vm._v(_vm._s(_vm.$t("global.button.cancel"))+" ")])],1),_c('b-col',{staticClass:"pl-2",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"w-100",attrs:{"pill":"","variant":"primary","type":"submit"}},[_vm._v(" Update Password ")])],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-bottom d-flex justify-content-between align-items-center pb-2"},[_c('div',[_c('h4',{staticClass:"section-title"},[_vm._v(" Reset Password ")])])])
}]

export { render, staticRenderFns }